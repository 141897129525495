import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import Stack from "@mui/material/Stack";
import {DataTable} from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import moment from "moment/moment";
import {GenericModal} from "../../../../../components/genericModal/genericModal";
import {DataTableContainerForLoanDetails} from "../../../utils";
import EditIcon from "@mui/icons-material/Edit";
import RescheduleInstallment from "../operations/rescheduleInstallment"
import {Toolbar} from "../../components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {Spinner} from "react-bootstrap";
import {contractManagementReducer, loansReducer} from "../../../reducer";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {toBengaliNumber} from "bengali-number";
import {calculateTimeRemaining} from "../../../../../utils/dateFormat/DateTimeFormat";
import {exportInExcel} from "../../../../../utils/excelExport";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/styles';
import PropTypes from "prop-types";
import {showNotifications} from "../../../../../utils/notification";
import { toast } from "react-toastify";


const OperationalLog = ({ contractDetails, ...props }) => {
    const [language, setLanguage] = useState("EN");

    const [contractId, setContractId] = useState('');

    const [editContractLogs, setEditContractLogs] = useState([]);
    const [installmentUpdateLogs, setInstallmentUpdateLogs] = useState([]);
    const [showEditContractLogTable, setShowEditContractLogTable] = useState(true);
    const [showInstallmentUpdateLogTable, setShowInstallmentUpdateLogTable] = useState(true);

    // File download related
    const [guidForFile, setGuidForFile] = useState('');

    // Tab styling related
    const useStyles = makeStyles((theme) => ({
        tab: {
            '&:hover': {
                color: '#F18D00 !important'
            },
            '&:focus': {
                color: '#F18D00 !important'
            },
            '&.Mui-selected': {
                color: '#F18D00 !important'
            },
            textTransform: 'none',
            ...theme.typography.h6
        },
        indicator: {
            backgroundColor: '#F18D00 !important'
        }
    }));
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const TabPanel = ({...props}) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}
                {...other}>
                {value === index && (
                    <Box p={2}>{children}</Box>
                )}
            </div>
        );
    }

    // Following option variable has been used exactly at the "Edit Contract" form
    const batteryUsageTypes = [
        {
            label: 'Service Battery',
            value: 'service'
        },
        {
            label: 'Primary Battery',
            value: 'primary'
        }
    ]

    const editContractOperationalLogColumns = [
        {
            field: 'device_serial_number',
            title: "Battery ID #",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>SB #{rowData.device_serial_number}</strong></h6>
                </>
            }
        },
        {
            field: 'duration',
            title: "Operational Duration",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <span className={'text-nowrap'}>{rowData.duration}</span>
                </>
            }
        },
        {
            field: 'repayment_amount',
            title: "Repayment Amount (Tk)",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    {new Intl.NumberFormat('en-IN').format(rowData.repayment_amount.toFixed(2))}
                </>
            }
        },
        {
            field: 'battery_status',
            title: "Battery Status",
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=> {
                return   <>
                    <strong>{rowData.battery_status}</strong>
                </>
            }
        }
    ]

    const installmentUpdateOperationalLogColumns = [
        {
            field: 'installment_no',
            title: "Installment",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.installment_no}</strong></h6>
                </>
            }
        },
        {
            field: 'created_at',
            title: "Time of update",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <span className={'text-nowrap'}>{moment(rowData.created_at).format("MMM DD,YYYY hh:mm a")}</span>
                </>
            }
        },
        {
            field: 'old_data',
            title: "Previous amount",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    {rowData.old_data.installment_amount? new Intl.NumberFormat('en-IN').format(rowData.old_data.installment_amount.toFixed(2)):'-'}
                </>
            }
        },
        {
            field: 'new_data',
            title: "Updated amount",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    {rowData.new_data.installment_amount? new Intl.NumberFormat('en-IN').format(rowData.new_data.installment_amount.toFixed(2)):new Intl.NumberFormat('en-IN').format(rowData.changed_installment_amount.toFixed(2))}
                </>
            }
        },
        {
            field: 'pk',
            title: "Supporting Doc",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    {props.collectingFile? <>
                        <span style={{color: "#F18D00"}}>{rowData.pk === guidForFile? 'Downloading...':'Download'}</span>
                    </>:<>
                        <a onClick={() => initiateFileDownload(rowData.pk)}>Download</a>
                    </>}
                </>
            }
        },
        {
            field: 'authorized_by',
            title: "Authorized By",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <span className={'text-nowrap'}>{rowData.authorized_by}</span>
                </>
            }
        },
        {
            field: 'user_name',
            title: "Updated By",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <span className={'text-nowrap'}>{rowData.user_name}</span>
                </>
            }
        }
    ]

    const {
        COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT,
        COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE,
        COLLECT_FILE
    } = props;

    useEffect(() => {
        setLanguage(props.language)
        COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT(contractDetails.contract_id)
        COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE(contractDetails.contract_id)
        setContractId(contractDetails.contract_id)
    }, [])

    const handleTabSelection = (event, newValue) => {
        setTabValue(newValue)
    }

    useEffect(() => {
        if (props.operationalLogsForEditContract && props.operationalLogsForEditContract.length > -1) {
            const logs = props.operationalLogsForEditContract
            setEditContractLogs(logs.map((log, index) => {
                let durationText = ''
                if (index === 0) {
                    durationText = moment(log.created_at).format("MMM DD, YYYY hh:mm a") + " - Now"
                } else {
                    durationText = moment(log.created_at).format("MMM DD, YYYY hh:mm a") + " - " + moment(logs[index - 1].created_at).format("MMM DD, YYYY hh:mm a")
                }
                let batteryType = batteryUsageTypes.filter((item) => item.value === log.new_data.battery_type)
                return {
                    device_serial_number: log.new_data.device_serial_number,
                    duration: durationText,
                    repayment_amount: log.new_data.contract_type === 'rent'? log.new_data.per_rent_payable:log.new_data.per_installment_payable,
                    battery_status: batteryType[0]? batteryType[0].label:''
                }
            }))
            setShowEditContractLogTable(logs.length > 0)
        } else {
            setEditContractLogs(undefined)
        }
    }, [props.operationalLogsForEditContract])

    useEffect(() => {
        if (props.operationalLogsForInstallmentUpdate && props.operationalLogsForInstallmentUpdate.length > -1) {
            const logs = props.operationalLogsForInstallmentUpdate
            setInstallmentUpdateLogs(logs.map((log, index) => {
                return {
                    ...log
                }
            }))
            setShowInstallmentUpdateLogTable(logs.length > 0)
        } else {
            setInstallmentUpdateLogs(undefined)
        }
    }, [props.operationalLogsForInstallmentUpdate])

    const exportData = () => {
        let presentTime = moment()
        let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
        let headerText = tabValue === 0? "Operational Logs - Edit Contract":"Operational Logs - Update Installment"
        let excelRows = tabValue === 0? [
            [headerText, "", "", ""],
            ["Contract ID: " + contractId, "", "", ""],
            [exportText, "", "", ""],
            ["", "", "", ""],
            ["", "", "", ""],
            ["Battery ID #", "Operational Duration", "Repayment Amount (Tk)", "Battery Status"]
        ]:[
            [headerText, "", "", "", ""],
            ["Contract ID: " + contractId, "", "", "", "", "", ""],
            [exportText, "", "", "", "", "", ""],
            ["", "", "", "", "", "", ""],
            ["", "", "", "", "", "", ""],
            ["Installment", "Time of update", "Previous amount(Tk)", "Updated amount(Tk)", "Authorized By", "Updated By"]
        ]
        let data = tabValue === 0? editContractLogs:installmentUpdateLogs
        for (let i=0; i<data.length; i++) {
            if (tabValue === 0) {
                excelRows.push(
                    [
                        data[i].device_serial_number,
                        data[i].duration,
                        new Intl.NumberFormat('en-IN').format(data[i].repayment_amount.toFixed(2)),
                        data[i].battery_status
                    ])
            } else {
                excelRows.push(
                    [
                        data[i].installment_no,
                        moment(data[i].created_at).format("MMM DD,YYYY hh:mm a"),
                        data[i].old_data? new Intl.NumberFormat('en-IN').format(data[i].old_data.installment_amount.toFixed(2)):'',
                        data[i].new_data? new Intl.NumberFormat('en-IN').format(data[i].new_data.installment_amount.toFixed(2)):'',
                        data[i].authorized_by,
                        data[i].user_name
                    ])
            }
        }
        let fileNameTitle = tabValue === 0? 'edit_contract':'installment_update'
        let fileName = fileNameTitle + '_operational_logs_of_' + contractId
        exportInExcel(excelRows, fileName)
    }

    const initiateFileDownload = (guid) => {
        setGuidForFile(guid)
        COLLECT_FILE(guid)
    }

    const handleDownload = (fileUrl) => {
        // Create a temporary anchor element
        const a = document.createElement('a');
        if (fileUrl) {
            a.href = fileUrl
            a.download = 'sample.txt'; // Sample file name

            // Append the anchor to the body (not necessary, but for safety)
            document.body.appendChild(a);

            // Programmatically click the anchor
            a.click();

            // Clean up by removing the anchor
            document.body.removeChild(a);
        } else {
            toast.info("No document is available for download!")
        }
    };

    useEffect(() => {
        if (props.file) {
            setGuidForFile('')
            handleDownload(props.file.file_link)
        }
    }, [props.file])

    useEffect(() => {
        if (props.errorRelatedToFileCollection) {
            showNotifications("error", props.errorRelatedToFileCollection)
        }
    }, [props.errorRelatedToFileCollection])

    return (
        <>
            <div className={'row mt-10'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-10 d-flex justify-content-between'}>
                    <span><h6><strong>Operational Log</strong></h6></span>
                    <>
                        {tabValue === 0 && showEditContractLogTable? <>
                            <img onClick={exportData} className={'ml-5'}
                                 src={require('../../../../../utils/asset/download-Button.svg')} alt="Download"/>
                        </>:<>
                            {tabValue === 1 && showInstallmentUpdateLogTable? <>
                                <img onClick={exportData} className={'ml-5'}
                                     src={require('../../../../../utils/asset/download-Button.svg')} alt="Download"/>
                            </>:''}
                        </>}
                    </>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-10'}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabSelection}
                        aria-label="operational logs"
                        classes={{ indicator: classes.indicator}}
                    >
                        <Tab label="Edit Contract" className={classes.tab}/>
                        <Tab label="Installment Update" className={classes.tab}/>
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                            {!props.editContractLogCollectionInProgress && editContractLogs? <>
                                {showEditContractLogTable? <>
                                    <DataTableContainerForLoanDetails>
                                        <DataTable language={language}
                                                   noDataAvailableMessageInEnglish={'No operational logs available'}
                                                   columns={editContractOperationalLogColumns} data={editContractLogs}
                                                   showToolbar={false} asyncPagination={false} pagination={true} pageSize={5}/>
                                    </DataTableContainerForLoanDetails>
                                </>:<>
                                    No operational logs available
                                </>}
                            </>:<>
                                <h6>
                                    <Skeleton animation="wave" variant="rectangular" width={'100%'} height={60}
                                              className={"d-flex justify-content-center align-items-center"}
                                              sx={{color: '#F18D00', backgroundColor: '#FAF9F6'}}>
                                        Collecting Edit Contract related operational logs ...
                                    </Skeleton>
                                </h6>
                            </>}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {!props.installmentUpdateLogCollectionInProgress && installmentUpdateLogs? <>
                            {showInstallmentUpdateLogTable? <>
                                <DataTableContainerForLoanDetails>
                                    <DataTable language={language}
                                               noDataAvailableMessageInEnglish={'No operational logs available'}
                                               columns={installmentUpdateOperationalLogColumns} data={installmentUpdateLogs}
                                               showToolbar={false} asyncPagination={false} pagination={true} pageSize={5}/>
                                </DataTableContainerForLoanDetails>
                            </>:<>
                                No operational logs available
                            </>}
                        </>:<>
                            <h6>
                                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={60}
                                          className={"d-flex justify-content-center align-items-center"}
                                          sx={{color: '#F18D00', backgroundColor: '#FAF9F6'}}>
                                    Collecting Installment Update related operational logs ...
                                </Skeleton>
                            </h6>
                        </>}
                    </TabPanel>
                </div>
                <div className={'col-md-1'}></div>
            </div>
        </>
    )
}

OperationalLog.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        operationalLogsForEditContract: state.contractManagementReducer.operationalLogsForEditContract,
        editContractLogCollectionInProgress: state.contractManagementReducer.editContractLogCollectionInProgress,
        operationalLogsForInstallmentUpdate: state.contractManagementReducer.operationalLogsForInstallmentUpdate,
        installmentUpdateLogCollectionInProgress: state.contractManagementReducer.installmentUpdateLogCollectionInProgress,
        file: state.contractManagementReducer.file,
        collectingFile: state.contractManagementReducer.collectingFile,
        errorRelatedToFileCollection: state.contractManagementReducer.errorRelatedToFileCollection
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(OperationalLog);