import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { withRoles } from "../../../../router/SecuredRoute";
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {Button, Form, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {DataTable, LoadingSpinner, SolInput} from "../../../../components";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import Alert from '@mui/material/Alert';
import {ExportToCsv} from "export-to-csv";
import {csvToArray} from "../../../../utils/utilityFunctions";
import {showNotifications} from "../../../../utils/notification";
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import AlertTitle from '@mui/material/AlertTitle';


const ManualCashPayment = ({ data, updateTitle, handleCashPayment, cancelProcess, ...props }) => {
    const {register, errors, handleSubmit} = useForm();
    const [language, setLanguage] = useState("EN");
    const [garageOwnerUserName, setGarageOwnerUserName] = useState("");
    const [showBulkInvoiceUploadField, setShowBulkInvoiceUploadField] = useState(false);
    const [showInvoices, setShowInvoices] = useState(false);
    const [payableAmount, setPayableAmount] = useState(0);
    const [clearedAmount, setClearedAmount] = useState(0);
    const [residueAmount, setResidueAmount] = useState(0);
    const [paymentDate, setPaymentDate] = useState('');
    const [payload, setPayload] = useState('');
    const [invoices, setInvoices] = useState(undefined);
    const [paidInvoices, setPaidInvoices] = useState([]);
    const [showError, setShowError] = useState(false);
    const [downloadingInvoiceList, setDownloadingInvoiceList] = useState(false);
    const [makeInvoiceStatusNotUpdatable, setMakeInvoiceStatusNotUpdatable] = useState(false);
    const [oldCsvIsUsed, setOldCsvIsUsed] = useState(false);
    const [fileIsCorrupted, setFileIsCorrupted] = useState(false);
    const [errorInTheUploadedCsv, setErrorInTheUploadedCsv] = useState(false);
    const [errorRowCountInTheUploadedCsv, setErrorRowCountInTheUploadedCsv] = useState(0);
    const [errorNoInvoiceMatched, setErrorNoInvoiceMatched] = useState(false);

    const transactionServiceName = getServiceName('transactionService')
    const manualCashPaymentFeatureName = getFeatureName('newDepositFeature')
    const uploadActionName = getActionName('uploadAction')

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const invalidName = language === 'EN'? 'Invalid name!':'ভুল নাম!'
    const informationIsRequired = 'Above information is required!'
    const wrongFileTypeMessage = 'Invalid file type!'
    const wrongFileSizeMessage = 'File size has exceeded the max size limit!'

    const invoiceListColumnsWithUpdatableStatus = [
        {
            field: 'serialNumber',
            title: 'Smart Battery',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span><strong>Smart Battery # {rowData.serialNumber}</strong></span><br/>
                    <span>Invoice # {rowData.invoice_id}</span><br/>
                    <span>Contract # {rowData.contract_id}</span>
                </>
            }
        },
        {
            field: 'payableAmount',
            title: 'Amount Due (Tk)',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                let amount = "Tk " + new Intl.NumberFormat('en-IN').format(rowData.payableAmount.toFixed(2))
                let rangeText = ''
                if (rowData.invoice_for === 'installment') {
                    let paymentDate = moment(rowData.installment_due_on).format("MMM DD YYYY")
                    let startDate = moment.utc(rowData.created_at).local().format("MMM DD YYYY")
                    let splittedStartDate = startDate.split(" ")
                    let splittedPaymentDate = paymentDate.split(" ")
                    if (splittedStartDate[2] === splittedPaymentDate[2]) {
                        // Year is equal
                        if (splittedStartDate[0] === splittedPaymentDate[0]) {
                            // Both year and month is equal
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        } else {
                            // Year equal but month is not
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[0] + " " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        }
                    } else {
                        // Year is not equal
                        rangeText = startDate + ' - ' + paymentDate
                    }
                } else if (rowData.invoice_for === 'rent_payment') {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                } else {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                }
                return   <>
                    <div>{amount}</div>
                    <div>{rangeText}</div>
                </>
            }
        },
        {
            field: 'paidAmount',
            title: 'Paid (Tk)',
            cellStyle: {
                // paddingRight: '0',
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{new Intl.NumberFormat('en-IN').format(rowData.paidAmount.toFixed(2))}</strong></h6>
                </>
            }
        },
        {
            field: 'invoiceStatus',
            title: 'Paid?',
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData) => {
                return (
                    <>
                        {/* We haven't used external css because, only during inline styling the downward arrow on
                            the right side of the select box has become 'white' - Noor Reza, 24th August, 2023, 6:40 pm */}
                        <Form.Group>
                            <Form.Control as="select" size={"sm"} ref={(el) => {
                                if (el) {
                                    el.style.setProperty('color', '#ffffff', 'important');
                                    if (rowData.invoiceStatus === 'paid') {
                                        el.style.setProperty('background-color', '#0b6e4f', 'important');
                                        el.style.setProperty('border-color', '#0b6e4f', 'important');
                                    } else if (rowData.invoiceStatus === 'overdue') {
                                        el.style.setProperty('background-color', '#E71D36', 'important');
                                        el.style.setProperty('border-color', '#E71D36', 'important');
                                    } else {
                                        el.style.setProperty('background-color', '#F18D00', 'important');
                                        el.style.setProperty('border-color', '#F18D00', 'important');
                                    }
                                    el.style.setProperty('margin-top', '12%', 'important');
                                    if (!isTabletOrMobile) {
                                        el.style.setProperty('width', '60%', 'important');
                                    }
                                }
                            }} onChange={(e) => handleInvoiceStatusUpdate(e.target.value, rowData)}
                                          defaultValue={rowData.invoiceStatus}>
                                <option value={(rowData.invoiceStatus === 'due' || rowData.previousInvoiceStatus === 'due')? 'due':
                                    rowData.invoiceStatus === 'overdue' || rowData.previousInvoiceStatus === 'overdue'? 'overdue':'partial_paid'} ref = {(el) => {
                                    if (el) {
                                        el.style.setProperty('color', '#000000', 'important');
                                        el.style.setProperty('background-color', '#ffffff', 'important');
                                    }
                                }}>{(rowData.invoiceStatus === 'overdue' || rowData.previousInvoiceStatus === 'overdue')? 'Overdue':'Due'}</option>
                                <option value={'paid'} ref = {(el) => {
                                    if (el) {
                                        el.style.setProperty('color', '#000000', 'important');
                                        el.style.setProperty('background-color', '#ffffff', 'important');
                                    }
                                }}>Paid</option>
                            </Form.Control>
                        </Form.Group>
                    </>
                )
            }
        }
    ]

    const invoiceListColumnsWithNotUpdatatbleStatus =  [
        {
            field: 'serialNumber',
            title: 'Smart Battery',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {!rowData.errorInSmartBattery? <><span><strong>Smart Battery # {rowData.serialNumber}</strong></span><br/></>:<>
                        <Alert severity="error" icon={false}>Smart Battery # <strong>{rowData.serialNumber}</strong></Alert>
                    </>}
                    <span>Invoice # {rowData.invoice_id}</span><br/>
                    {!rowData.errorInContractId? <><span>Contract # {rowData.contractId}</span></>:<>
                        <Alert severity="error" icon={false}>Contract # <strong>{rowData.contractId}</strong></Alert>
                    </>}
                </>
            }
        },
        {
            field: 'payableAmount',
            title: 'Amount Due (Tk)',
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                let amount = "Tk " + new Intl.NumberFormat('en-IN').format(rowData.payableAmount.toFixed(2))
                let rangeText = ''
                if (rowData.invoice_for === 'installment') {
                    let paymentDate = moment(rowData.installment_due_on, "YYYY-MM-DD").format("MMM DD YYYY")
                    let startDate = moment.utc(rowData.created_at).local().format("MMM DD YYYY")
                    let splittedStartDate = startDate.split(" ")
                    let splittedPaymentDate = paymentDate.split(" ")
                    if (splittedStartDate[2] === splittedPaymentDate[2]) {
                        // Year is equal
                        if (splittedStartDate[0] === splittedPaymentDate[0]) {
                            // Both year and month is equal
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        } else {
                            // Year equal but month is not
                            rangeText = splittedStartDate[0] + " " + splittedStartDate[1] + " - " + splittedPaymentDate[0] + " " + splittedPaymentDate[1] + ", " + splittedStartDate[2]
                        }
                    } else {
                        // Year is not equal
                        rangeText = startDate + ' - ' + paymentDate
                    }
                } else if (rowData.invoice_for === 'rent_payment') {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                } else {
                    rangeText = moment(rowData.created_at).format("MMM DD, YYYY")
                }
                return   <>
                    {!rowData.errorInPayableAmount? <div>{amount}</div>:<>
                        <Alert severity="error" icon={false}><h6>{amount}</h6></Alert>
                    </>}
                    <div>{rangeText}</div>
                </>
            }
        },
        {
            field: 'paidAmount',
            title: 'Paid (Tk)',
            cellStyle: {
                // paddingRight: '0',
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                let amount = new Intl.NumberFormat('en-IN').format(rowData.paidAmount.toFixed(2))
                return   <>
                    {!(rowData.errorInPayableAmount && rowData.paidAmount === rowData.payableAmount)? <h6><strong>{amount}</strong></h6>:<>
                        <Alert severity="error" icon={false} ref={(el) => {
                            if (el && !isTabletOrMobile) {
                                el.style.setProperty('float', 'right', 'important')
                            }
                        }}><h6><strong>{amount}</strong></h6></Alert>
                    </>}
                </>
            }
        },
        {
            field: 'invoiceStatus',
            title: 'Paid?',
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                return <>
                    {rowData.errorInStatus? <>
                        <Alert severity="error" ref={(el) => {
                            if (el) {
                                el.style.setProperty('float', 'right', 'important')
                            }
                        }}><h6>{rowData.invoiceStatus}</h6></Alert>
                    </>:<>
                        {rowData.invoiceStatus === 'paid'? <>
                            <Button size={'sm'} ref={(el) => {
                                if (el) {
                                    el.style.setProperty('color', '#ffffff', 'important')
                                    el.style.setProperty('background-color', '#0b6e4f', 'important')
                                    el.style.setProperty('border-color', '#0b6e4f', 'important')
                                }
                            }}>Paid</Button>
                        </>:<>
                            {rowData.invoiceStatus === 'due'? <>
                                <Button variant={'outline-dark'} size={'sm'} disabled={true}>Due</Button>
                            </>:<>
                                {/* Overdue status */}
                                <Button size={'sm'} variant={'outline-danger'} disabled={true}>Overdue</Button>
                            </>}
                        </>}
                    </>}
                </>
            }
        }
    ]

    const exportedCsvColumns = ['Invoice_ID', 'SB_Number', 'Contract_ID', 'Payable_amount', 'Status']

    const {
        COLLECT_INVOICES,
        RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        setGarageOwnerUserName(data[0].garage_owner_phone)
        setShowBulkInvoiceUploadField(checkAuthorization(props.authorization, transactionServiceName,
            manualCashPaymentFeatureName, [uploadActionName]))
        return ()=>{
            RETURN_TO_INITIAL_STATE_FOR_MANUAL_CASH_PAYMENT()
        }
    }, [])

    const parseUploadedCsv = (file) => {
        const reader = new FileReader();

        reader.onload = function (e) {
            let uploadedInvoices = csvToArray(e.target.result)
            let columns = Object.keys(uploadedInvoices[uploadedInvoices.length - 1])
            let corruptedFile = false
            if (columns.length !== exportedCsvColumns.length) {
                corruptedFile = true
                setFileIsCorrupted(true)
            } else {
                for (let i=0; i<exportedCsvColumns.length; i++) {
                    if (exportedCsvColumns[i] !== columns[i]) {
                        corruptedFile = true
                        setFileIsCorrupted(true)
                        break
                    }
                }
            }
            if (!corruptedFile) {
                let updatedClearedAmount = 0
                let updatedPaidInvoicesIds = []
                let errorRowCount = 0
                let updatedInvoices = invoices.map((invoice) => {
                    let ifThisInvoiceIsUploaded = uploadedInvoices.filter((item) => {
                        let invoiceId = item.Invoice_ID.replace(/['"]+/g, '')
                        if (invoiceId === invoice.invoice_id) {
                            return item
                        }
                    })
                    // Including the only correct Invoice IDs
                    if (ifThisInvoiceIsUploaded.length > 0) {
                        let updatedPaidAmount = invoice.paidAmount
                        let payableAmount = parseFloat(ifThisInvoiceIsUploaded[0].Payable_amount.replace(/['"]+/g, ''))
                        let smartBattery = ifThisInvoiceIsUploaded[0].SB_Number.replace(/['"]+/g, '')
                        let contractId = ifThisInvoiceIsUploaded[0].Contract_ID.replace(/['"]+/g, '')
                        let status = ifThisInvoiceIsUploaded[0].Status.replace(/['"]+/g, '')
                        let errorInStatus = false
                        let errorInSmartBattery = false
                        let errorInContractId = false
                        let errorInPayableAmount = false
                        if (status !== invoice.invoiceStatus && status.toLowerCase() !== 'paid') {
                            setErrorInTheUploadedCsv(true)
                            errorInStatus = true
                        }
                        if (payableAmount !== invoice.payable_amount) {
                            setErrorInTheUploadedCsv(true)
                            errorInPayableAmount = true
                        }
                        if (smartBattery !== invoice.device_sl) {
                            setErrorInTheUploadedCsv(true)
                            errorInSmartBattery = true
                        }
                        if (contractId !== invoice.contract_id) {
                            setErrorInTheUploadedCsv(true)
                            errorInContractId = true
                        }
                        if (errorInStatus || errorInPayableAmount || errorInSmartBattery || errorInContractId) {
                            errorRowCount += 1
                        }
                        if (status.toLowerCase() === 'paid') {
                            updatedPaidAmount = payableAmount
                            updatedClearedAmount += payableAmount
                            updatedPaidInvoicesIds.push(ifThisInvoiceIsUploaded[0].Invoice_ID.replace(/['"]+/g, ''))
                            status = 'paid'
                        } else if (status.toLowerCase() === 'due' || status.toLowerCase() === 'partial_paid') {
                            status = 'due'
                        } else if (status.toLowerCase() === 'overdue') {
                            status = 'overdue'
                        } else {
                            setErrorInTheUploadedCsv(true)
                            if (!errorInStatus) {
                                errorRowCount += 1
                            }
                        }

                        return {
                            ...invoice,
                            serialNumber: smartBattery,
                            errorInSmartBattery: errorInSmartBattery,
                            contractId: contractId,
                            errorInContractId: errorInContractId,
                            payableAmount: payableAmount,
                            errorInPayableAmount: errorInPayableAmount,
                            invoiceStatus: status,
                            errorInStatus: errorInStatus,
                            paidAmount: updatedPaidAmount
                        }
                    }
                })
                if (updatedInvoices && updatedInvoices.length !== 0) {
                    setInvoices(updatedInvoices.filter((item) => !!item))
                } else {
                    setErrorNoInvoiceMatched(true)
                }
                if (updatedClearedAmount < payload.amount) {
                    setResidueAmount(payload.amount - updatedClearedAmount)
                }
                setErrorRowCountInTheUploadedCsv(errorRowCount)
                setClearedAmount(updatedClearedAmount)
                setPaidInvoices(updatedPaidInvoicesIds)
            }
        };
        reader.readAsText(file)
    }

    const savePayment = (rechargeData) => {
        setErrorNoInvoiceMatched(false)
        setFileIsCorrupted(false)
        setErrorInTheUploadedCsv(false)
        let payload = {
            ...rechargeData,
            'username': garageOwnerUserName
        }
        if (rechargeData['receipt_file'][0]) {
            payload['receipt_file'] = rechargeData['receipt_file'][0]
        } else {
            delete payload['receipt_file']
        }
        setClearedAmount(0)
        setResidueAmount(0)
        if (showBulkInvoiceUploadField && rechargeData.uploaded_recharge_csv[0]) {
            payload['payment_file'] = rechargeData['uploaded_recharge_csv'][0]
            if (!invoices || invoices.length === 0) {
                // Dealing with case when user tries to upload an old downloaded csv
                setOldCsvIsUsed(true)
                COLLECT_INVOICES({'borrower_guid':data[0].garage_owner_guid, 'return_all_due_invoice':'yes'})
            } else {
                setOldCsvIsUsed(false)
                parseUploadedCsv(rechargeData.uploaded_recharge_csv[0])
            }
            setMakeInvoiceStatusNotUpdatable(true)
        } else {
            delete payload['uploaded_recharge_csv']
            COLLECT_INVOICES({'borrower_guid':data[0].garage_owner_guid, 'return_all_due_invoice':'yes'})
        }
        setPayload(payload)
        setPaymentDate(moment().format("MMM DD, YYYY"))
        updateTitle("Which Invoices would you like to mark as Paid?")
        setShowInvoices(true)
    }

    useEffect(() => {
        if (props.invoices && props.invoices.length > -1) {
            const invoices = props.invoices
            if (downloadingInvoiceList) {
                setDownloadingInvoiceList(false)
                if (invoices.length === 0) {
                    exportAllDueInvoices({
                        Invoice_ID: "",
                        SB_Number: "No",
                        Contract_ID: "Invoices",
                        Payable_amount: "Available",
                        Status: ""
                    })
                } else {
                    let dataForCsv = invoices.map((invoice) => {
                        return {
                            Invoice_ID: invoice.invoice_id,
                            SB_Number: invoice.device_sl,
                            Contract_ID: invoice.contract_id,
                            Payable_amount: invoice.payable_amount - invoice.paid_amount,
                            Status: invoice.invoice_status
                        }
                    })
                    exportAllDueInvoices(dataForCsv)
                }
            }
            let totalDuePayable = 0
            setInvoices(invoices.map((invoice) => {
                let payableAmount = invoice.payable_amount - invoice.paid_amount
                totalDuePayable += payableAmount
                return {
                    ...invoice,
                    serialNumber: invoice.device_sl,
                    payableAmount: payableAmount,
                    invoiceStatus: invoice.invoice_status,
                    paidAmount: 0
                }
            }))
            setPayableAmount(new Intl.NumberFormat('en-IN').format(totalDuePayable.toFixed(2)))
        } else {
            setInvoices([])
        }
    }, [props.invoices])

    useEffect(() => {
        if (invoices && invoices.length > -1 && oldCsvIsUsed) {
            // Dealing with old downloaded csv
            parseUploadedCsv(payload.payment_file)
            setOldCsvIsUsed(false)
        }
    }, [invoices])

    const returnToCashPaymentFirstStep = () => {
        updateTitle(data[0].garage_owner + ', ' + data[0].name)
        setShowInvoices(false)
        setShowError(false)
        setPaidInvoices([])
    }

    const handleInvoiceStatusUpdate = (status, invoiceData) => {
        let updatedClearedAmount = clearedAmount
        setInvoices(invoices.map((invoice) => {
            let updatedStatus = invoice.invoiceStatus
            // When invoice status is set to 'paid', we are preserving the previous status, otherwise not
            // - Noor Reza, 4th January, 2023 2:00 PM
            let previousInvoiceStatus = invoice.invoiceStatus === 'paid'? invoice.previousInvoiceStatus:invoice.invoiceStatus
            let updatedPaidAmount = invoice.paidAmount
            if (invoice.pk === invoiceData.pk) {
                let updatedPaidInvoicesIds = paidInvoices
                if (status === 'paid') {
                    updatedStatus = status
                    updatedPaidAmount += invoiceData.payableAmount
                    updatedClearedAmount += invoiceData.payableAmount
                    if (updatedClearedAmount < payload.amount) {
                        setResidueAmount(payload.amount - updatedClearedAmount)
                    }
                    updatedPaidInvoicesIds.push(invoiceData.invoice_id)
                } else {
                    updatedStatus = status
                    updatedPaidAmount -= invoiceData.payableAmount
                    updatedClearedAmount -= invoiceData.payableAmount
                    if (updatedClearedAmount < payload.amount) {
                        setResidueAmount(payload.amount - updatedClearedAmount)
                    }
                    updatedPaidInvoicesIds = paidInvoices.filter(item => item !== invoiceData.invoice_id)
                }
                setPaidInvoices(updatedPaidInvoicesIds)
            }

            return {
                ...invoice,
                serialNumber: invoice.device_sl,
                payableAmount: invoice.payableAmount,
                invoiceStatus: updatedStatus,
                previousInvoiceStatus: previousInvoiceStatus,
                paidAmount: updatedPaidAmount
            }
        }))
        setClearedAmount(updatedClearedAmount)
    }

    const saveFinalPayload = () => {
        if (parseFloat(payload.amount) < clearedAmount) {
            setShowError(true)
        } else {
            setShowError(false)
            let finalPayload = {
                ...payload,
                'invoice_ids': paidInvoices
            }
            handleCashPayment(finalPayload)
        }
    }

    const initiateInvoiceDownloadProcess = () => {
        setDownloadingInvoiceList(true)
        COLLECT_INVOICES({'borrower_guid':data[0].garage_owner_guid, 'return_all_due_invoice':'yes'})
    }

    const exportAllDueInvoices = (dataToExport) => {
        let currentDate = moment().format("DD_MMM_YYYY")
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: '',
            filename: 'due_invoices_of_' + data[0].name + '_at_' + currentDate,
            useTextFile: false,
            useBom: true,
            headers: exportedCsvColumns
        }
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(dataToExport)
    }

    useEffect(() => {
        if (props.invoicesCollectionRelatedErrorMessage) {
            showNotifications('error', props.invoicesCollectionRelatedErrorMessage)
        }
    }, [props.invoicesCollectionRelatedErrorMessage])

    return (
        <div>
            <Form onSubmit={handleSubmit(savePayment)} style={showInvoices? {display: 'none'}:{}}>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Amount (Tk)&nbsp;<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                type={"number"}
                                name={"amount"}
                                step={"any"}
                                placeholder={"0.0"}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired
                                })}
                            />
                            {errors.amount && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.amount.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Received By&nbsp;<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                type={"text"}
                                name={"recieved_by_person_name"}
                                placeholder={"Name of the Person"}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired,
                                    validate: {
                                        isValidName: value => (!(/^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g.test(value)) || !value) || invalidName
                                    }
                                })}
                            />
                            {errors.recieved_by_person_name && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.recieved_by_person_name.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Money Receipt Reference</Form.Label>
                            </div>
                            <SolInput
                                type={"text"}
                                name={"receipt_reference"}
                                placeholder={"Enter money receipt reference"}
                                autoComplete={"off"}
                                ref={register()}
                            />
                            {errors.receipt_reference && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.receipt_reference.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Document</Form.Label><br/>
                            </div>
                            <SolInput type="file" name={"receipt_file"} ref={register({
                                validate: {
                                    isValidType: (value) => {if (value && value[0] && !["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        "application/vnd.ms-excel",
                                        "application/pdf", "text/csv",
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        "application/msword", "image/jpeg", "image/webp", "image/png"].includes(value[0].type)) {return wrongFileTypeMessage}},
                                    isValidFileSize: (value) => {if (value && value[0] && value[0].size > 100000) {return wrongFileSizeMessage}}
                                }
                            })}/>
                            {errors.receipt_file && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.receipt_file.message}</div>}
                            <small>Accepted file types: *.xlsx, *.xls, *.pdf, *.csv, *.docx, *.doc, *.jpeg, *.jpg, *.webp, *.png</small><br/>
                            <small>Max accepted size: 10 MB</small>
                        </Form.Group>
                    </div>
                    {showBulkInvoiceUploadField? <>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Upload Cash Recharge Data (CSV)</Form.Label><br/>
                                </div>
                                <SolInput type="file" name={"uploaded_recharge_csv"} ref={register({
                                    validate: {
                                        isValidType: (value) => {if (value && value[0] && value[0].type !== "text/csv") {return wrongFileTypeMessage}}
                                    }
                                })}/>
                                {errors.uploaded_recharge_csv && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.uploaded_recharge_csv.message}</div>}
                                {props.invoiceCollectionInProgress? <>
                                    <span style={{color: "#F18D00"}}>Downloading Pending invoice List....</span>
                                </>:<>
                                    <a style={{color: "#F18D00"}} onClick={initiateInvoiceDownloadProcess}>Download Pending invoice List</a>
                                </>}
                            </Form.Group>
                        </div>
                    </>:null}
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="warning" size={'lg'} type="submit"
                                disabled={props.invoiceCollectionInProgress} className={'ml-3 float-right'}>
                            Recharge
                        </Button>
                        <Button variant="outline-dark" size={'lg'} onClick={cancelProcess}
                                className={'float-right'}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Form>
            <div style={!showInvoices? {display: 'none'}:{}}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h6>{data? data[0].garage_owner + ', ' + data[0].name:''}</h6>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col-md-12'}>
                        <h6><strong>Recharging Amount: Tk {new Intl.NumberFormat('en-IN').format(payload?.amount)} | Invoice Payable: Tk {payableAmount} | Cleared Amount: Tk {new Intl.NumberFormat('en-IN').format(clearedAmount.toFixed(2))}</strong></h6>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col-md-12'}>
                        <h6><strong>Residue Amount: Tk {new Intl.NumberFormat('en-IN').format(parseFloat(residueAmount).toFixed(2))} <span style={{color: '#0b6e4f'}}>(Will be added to the user's account)</span></strong></h6>
                    </div>
                </div>
                <div className={'row pt-2'}>
                    <div className={'col-md-12'}>
                        <h6><strong>Payment Date: {paymentDate} | Received By: {payload?.recieved_by_person_name} {payload?.receipt_reference? '| Money Receipt Ref: ' + payload.receipt_reference:''}</strong></h6>
                    </div>
                </div>
                {payload?.receipt_file || payload?.payment_file? <>
                    <div className={'row pt-2'}>
                        {payload?.receipt_file?.name? <>
                            <div className={'col-md-6'} style={{display: "flex", flexDirection: "row", color: '#F18D00'}}>
                                <FilePresentIcon/>&nbsp;<strong>{payload?.receipt_file?.name}</strong>
                            </div>
                        </>:''}
                        {payload?.payment_file? <>
                            <div className={'col-md-6'} style={{display: "flex", flexDirection: "row", color: '#F18D00'}}>
                                <FilePresentIcon/>&nbsp;<strong>{payload?.payment_file?.name}</strong>
                            </div>
                        </>:''}
                    </div>
                </>:''}
                <div className={'row pt-6'}>
                    <div className={'col-md-12'}>
                        {fileIsCorrupted? <>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                Uploaded <strong>{payload?.payment_file?.name}</strong> file (for Cash Recharge) should have exactly following five column headers: <strong>Invoice_ID</strong>, <strong>SB_Number</strong>, <strong>Contract_ID</strong>, <strong>Payable_amount</strong>, <strong>Status</strong>
                            </Alert>
                        </>:errorNoInvoiceMatched? <>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                Uploaded <strong>{payload?.payment_file?.name}</strong> file (for Cash Recharge) contains no invoices for <strong>{data[0].garage_owner + ', ' + data[0].name}</strong>
                            </Alert>
                        </>:<>
                            {!props.invoiceCollectionInProgress? <>
                                <DataTable
                                    language={language}
                                    columns={makeInvoiceStatusNotUpdatable? invoiceListColumnsWithNotUpdatatbleStatus:invoiceListColumnsWithUpdatableStatus}
                                    data={invoices? invoices:[]}
                                    noDataAvailableMessageInEnglish={'There are no due invoices'}
                                    showToolbar={false}
                                    asyncPagination={false}
                                    isLoading={props.tableLoading}
                                    pagination={true}
                                    pageSize={5}
                                />
                            </>:<LoadingSpinner language={language} loadingSubText={showBulkInvoiceUploadField && payload?.payment_file? "Verifying due invoices ...":"Collecting due invoices ..."} />}
                        </>}
                    </div>
                </div>
                {errorInTheUploadedCsv? <>
                    <div className={'row pt-4'}>
                        <div className={'col-md-12'}>
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                There {errorRowCountInTheUploadedCsv === 1? 'is a row which has':'are ' + errorRowCountInTheUploadedCsv + ' rows which have'} wrong inputs in the uploaded <strong>{payload?.payment_file?.name}</strong> file (for Cash Recharge)! {errorRowCountInTheUploadedCsv === 1? 'It is':'They are'} red colored at the above table.
                            </Alert>
                        </div>
                    </div>
                </>:null}
                {showError? <>
                    <div className={'row pt-4'}>
                        <div className={"col-md-12"}>
                            <Alert severity="error" variant={"filled"} onClose={() => {setShowError(false)}}><strong>Cleared amount</strong> should not be greater than the <strong>Recharging amount</strong>!</Alert>
                        </div>
                    </div>
                </>:null}
                <div className={'row pt-2'}>
                    <div className={"col-md-12"}>
                        <Button size={'lg'} onClick={returnToCashPaymentFirstStep} className={'ml-2 float-left'}
                                style={{'backgroundColor': '#8C8C9B', 'borderColor': '#8C8C9B'}}
                                disabled={props.manualCashPaymentInProgress || props.invoiceCollectionInProgress}>
                            Go Back
                        </Button>
                        <Button variant="warning" size={'lg'} type="button"
                                disabled={props.manualCashPaymentInProgress || props.invoiceCollectionInProgress ||
                                    !invoices || (invoices && invoices.length === 0) || paidInvoices.length === 0 ||
                                    errorInTheUploadedCsv}
                                className={'float-right'} onClick={saveFinalPayload}>
                            {props.manualCashPaymentInProgress? <><Spinner animation={'border'} size={'md'}
                                                                           variant={'light'}/>&nbsp;</>:null}Save
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ManualCashPayment.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        invoices: state.borrowersReducer.invoices,
        invoicesCollectionRelatedErrorMessage: state.borrowersReducer.invoicesCollectionRelatedErrorMessage,
        invoiceCollectionInProgress: state.borrowersReducer.invoiceCollectionInProgress,
        manualCashPaymentInProgress: state.borrowersReducer.manualCashPaymentInProgress
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(ManualCashPayment));