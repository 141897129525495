import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {Toolbar} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {makeStyles} from "@material-ui/styles";
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import {Controller, useForm} from "react-hook-form";
import {Button, Form, Popover, Spinner} from "react-bootstrap";
import Select from "react-select";
import moment from "moment/moment";
import InfoIcon from "@mui/icons-material/Info";
import Overlay from "react-bootstrap/Overlay";
import {DayPickerRangeController} from "react-dates";
import {GenericModal, LoadingSpinner, SolTypography} from "../../../../components";
import {isValidPhoneNumber} from "libphonenumber-js";
import {showNotifications} from "../../../../utils/notification";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {DataTableContainer} from "../../../contractManagement/utils";
import DataTable from "../../../../components/dataTable/DataTable";
import NewCashDeposit from "./newCashDeposit";
import {exportInExcel} from "../../../../utils/excelExport";
import {requestCycle} from "../../../borrowers/utils";
import {toast} from "react-toastify";
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const Deposits = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError, getValues} = useForm();
    const subHeader = useSubheader();
    const [language, setLanguage] = useState(null);
    // Deposits
    const [page, setPage] = useState(1)
    const [deposits, setDeposits] = useState(undefined)
    const [showInProgressModal, setShowInProgressModal] = useState(false)
    const [latestGarageInfo, setLatestGarageInfo] = useState(undefined)

    // Filter form related
    const [showFilter, setShowFilter] = useState(true);
    // Transaction Date field related
    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [showTransactionDatepicker, setShowTransactionDatepicker] = useState(false);
    const [targetForTransactionDateField, setTargetForTransactionDateField] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');
    // Wallet Number related
    const [walletNumber, setWalletNumber] = useState('');
    // Garage selection related
    const [garages, setGarages] = useState([]);
    // Borrower selection related
    const [borrowers, setBorrowers] = useState([]);
    // Channel selection related
    const channels = [
        {label: 'Digital Recharge', value: 'bkash'},
        {label: 'Cash Recharge', value: 'cash'}
    ]
    // Filter process related
    const [filterParameters, setFilterParameters] = useState({});

    // Create new cash deposit
    const [showCreateCashDepositModal, setShowCreateCashDepositModal] = useState(false)
    const [titleOfTheNewCashDepositModal, setTitleOfTheNewCashDepositModal] = useState("New Cash Deposit")
    const transactionServiceName = getServiceName('transactionService')
    const newCashDepositFeatureName = getFeatureName('newDepositFeature')
    const createActionName = getActionName('createAction')

    const pageTitle = 'Deposits'

    const {
        GET_GARAGE_LIST,
        COLLECT_DEPOSITS,
        COLLECT_DEPOSITS_FOR_EXPORT,
        COLLECT_DEPOSITS_DETAILS,
        COLLECT_GARAGE_LIST,
        SAVE_NEW_CASH_DEPOSIT,
        RETURN_TO_INITIAL_STATE,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        setPage(1)
        // API call related to filter fields
        GET_GARAGE_LIST()
        // Initial deposit list collection process
        let today = moment()
        let sixDaysAgo = moment().subtract(6, 'days')
        setStartDate(sixDaysAgo)
        setEndDate(today)
        setFilterParameters({from_date: sixDaysAgo.format("YYYY-MM-DD"), to_date: today.format("YYYY-MM-DD")})
        COLLECT_DEPOSITS({from_date: sixDaysAgo.format("YYYY-MM-DD"), to_date: today.format("YYYY-MM-DD"), page: 1})

        if (checkAuthorization(props.authorization, transactionServiceName, newCashDepositFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" size={'sm'} type="button" onClick={initiateNewCashDepositProcess}
                            classes={classes} className={'ml-1'}>
                        Create New Cash Deposit
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setActionButtons(null)

        return ()=>{
            RETURN_TO_INITIAL_STATE();
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    // ---------------------- Deposit list related --------------- //
    const depositListColumns =  [
        {
            field: 'topup_id',
            title: "Recharge ID",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return <>#{rowData.topup_id}</>
            }
        },
        {
            field: 'created_at',
            title: "Date",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return <>{moment(rowData.created_at).format("MMM DD, YYYY - hh:mma")}</>
            }
        },
        {
            field: 'borrowerName',
            title: "Borrower's Name",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    {rowData.borrowerName}<br/>
                    <small>{rowData.garageName}</small>
                </>
            }
        },
        {
            field: 'payment_mood',
            title: 'TrxID',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    {rowData.payment_mood === 'cash'? <>
                        {rowData.receipt_file_name? <>
                            <a href={"#"} style={props.collectingDepositDetails? {pointerEvents: 'none'}:{}}
                               onClick={() => {downloadDocument(rowData)}}>{rowData.receipt_file_name}</a>
                        </>:'-'}
                    </>:<>
                        {rowData.bkash_transaction_id? rowData.bkash_transaction_id:'-'}
                    </>}
                </>
            }
        },
        {
            field: 'payment_mood',
            title: "Wallet Number",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    {rowData.payment_mood === 'cash'? <>-</>:<>
                        {/* We are checking based on payment_mode as 'sender' always has value even when the payment has
                         been done manually/via cash. During cash transaction 'sender' holds the username */}
                        {rowData.sender}
                    </>}
                </>
            }
        },
        {
            field: 'payment_mood',
            title: "Reference",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    {rowData.payment_mood === 'cash'? <>
                        {rowData.receipt_reference}
                    </>:<>
                        {rowData.reference}
                    </>}
                </>
            }
        },
        {
            field: 'payment_mood',
            title: "Channel",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    {rowData.payment_mood === 'cash'? <>
                        Cash Recharge
                    </>:<>
                        Digital Recharge<br/>
                        <small>bKash</small>
                    </>}
                </>
            }
        },
        {
            field: 'pay_amount',
            title: 'Deposit Amount',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
              return <>
                  TK {new Intl.NumberFormat('en-IN').format(rowData.pay_amount.toFixed(2))}<br/>
                  {/*<small>Balance - Tk {new Intl.NumberFormat('en-IN').format(rowData.total_recharged.toFixed(2))}</small>*/}
              </>
            }
        }
    ]
    useEffect(() => {
        if (props.deposits) {
            const deposits = props.deposits.results;
            setDeposits(deposits.map((deposit) => {
                let garageName = deposit.garage_name
                let borrowerName = deposit.payment_user_name
                // We have been checking against the latestGarageInfo at first, as latestGarageInfo has been updated/populated later during filter and pagination process
                // Where 'garages' has been updated at once, during options loading process of the "Garage" field at the filter form
                // - Noor Reza, 1st November, 2023 3:32 PM
                // Similar checking has been done during the export data preparation process
                if (latestGarageInfo && latestGarageInfo.length > 0) {
                    let filteredData = latestGarageInfo.filter(garage => garage.pk === deposit.garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        borrowerName = filteredData[0].garage_owner_name
                    }
                } else if (garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === deposit.garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        borrowerName = filteredData[0].garage_owner_name
                    }
                }
                return {
                    ...deposit,
                    borrowerName: borrowerName,
                    garageName: garageName
                }
            }))
        } else {
            setDeposits(undefined)
        }
    }, [props.deposits, garages, latestGarageInfo])
    useEffect(() => {
        if (props.errorMessageRegardingDepositCollection) {
            showNotifications('error', props.errorMessageRegardingDepositCollection)
        }
    }, [props.errorMessageRegardingDepositCollection])
    // Garage info used to show updated garage name
    useEffect(() => {
        if (props.garagesToShowUpdatedInfo) {
            const garages = props.garagesToShowUpdatedInfo;
            setLatestGarageInfo(garages)
        }
    }, [props.garagesToShowUpdatedInfo])

    // ---------------------- Filter related --------------------- //
    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    // ---- Transaction Date filter field ----//
    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    }, [start, end])
    const disableFutureDt = (current) => {
        const today = moment()
        return current.isAfter(today)
    }

    const [clickCount, setClickCount] = useState(0)
    const onDatesChange = ({startDate, endDate}) => {
        setClickCount(clickCount + 1)
        if (startDate && endDate) {
            setStartDate(startDate)
            setEndDate(endDate)
            if (clickCount === 1) {
                setClickCount(0)
                setShowTransactionDatepicker(false)
            }
        } else if (startDate && !endDate) {
            setStartDate(startDate)
            setEndDate(startDate)
        }
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const showHelpfulText = () => {
        return <>
            <div className={'container'} style={{color: "#F18D00"}}><InfoIcon size={'sm'}/> Selection is completed
                after <strong>two clicks on a single date</strong> or <strong>single click on two dates.</strong></div>
        </>
    }

    const transactionDateSelectionPopover = () => {
        return <>
            <Overlay
                show={showTransactionDatepicker}
                target={targetForTransactionDateField}
                placement="bottom-start"
            >
                <Popover id="popover-basic" bsPrefix={'custom-popover'}>
                    <Popover.Content bsPrefix={'custom-popover'}>
                        <DayPickerRangeController
                            startDate={start}
                            endDate={end}
                            numberOfMonths={2}
                            minimumNights={0}
                            firstDayOfWeek={0}
                            isOutsideRange={disableFutureDt}
                            onDatesChange={onDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                            renderKeyboardShortcutsButton={showHelpfulText}
                            onOutsideClick={(e) => {
                                if (e.toElement.name !== "dateRangePickerInput") {
                                    setShowTransactionDatepicker(false)
                                }
                            }}
                        />
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    }

    // ---- Garage list collection process related ----//
    // For filter form
    useEffect(() => {
        if (props.garages) {
            const garages = props.garages;
            setGarages(garages.map((garage) => {
                let garageName = garage.name
                if (latestGarageInfo && latestGarageInfo.length > 0) {
                    let filteredData = latestGarageInfo.filter(item => item.pk === garage.pk)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                    }
                    let selectedGarage = getValues('garage_guid')
                    if (selectedGarage && selectedGarage.pk === garage.pk) {
                        let garageForSelection = {
                            ...garage,
                            value: garage.pk,
                            label: garageName
                        }
                        setValue('garage_guid', garageForSelection)
                    }
                }
                return {
                    ...garage,
                    value: garage.pk,
                    label: garageName
                }
            }))

            setBorrowers(garages.map((garage) => {
                let borrowerName = garage.garage_owner_name
                if (latestGarageInfo && latestGarageInfo.length > 0) {
                    let filteredData = latestGarageInfo.filter(item => item.pk === garage.pk)
                    if (filteredData.length > 0) {
                        borrowerName = filteredData[0].garage_owner_name
                    }
                    let selectedBorrower = getValues('payment_user')
                    if (selectedBorrower && selectedBorrower.pk === garage.pk) {
                        let borrowerForSelection = {
                            ...garage,
                            label: borrowerName,
                            value: garage.pk
                        }
                        setValue('payment_user', borrowerForSelection)
                    }
                }
                return {
                    ...garage,
                    label: borrowerName,
                    value: garage.pk
                }
            }))
        }
    }, [props.garages, latestGarageInfo])
    // Showing error message related to the above process
    useEffect(() => {
        if (props.errorMessageForGarageListCollection) {
            showNotifications('error', props.errorMessageForGarageListCollection)
        }
    }, [props.errorMessageForGarageListCollection])

    const filterTheList = (data) => {
        let filterParams = {...data}
        if (data.payment_mood) {
            filterParams['payment_mood'] = data.payment_mood.value
        }
        if (data.garage_guid) {
            filterParams['garage_guid'] = data.garage_guid.value
        }
        if (data.payment_user) {
            // Note that, the value of 'data.garage_guid.value' and 'data.payment_user.value' are same as they are loaded from garage list
            filterParams['garage_guid'] = data.payment_user.value
            delete filterParams['payment_user']
        }
        if (data.sender) {
            setWalletNumber(data.sender)
            // Including 'Digital recharge' as 'Channel' at the filter parameters when user searches based
            // on the wallet number. As "sender" has value of username when cash recharge happens, at database
            filterParams['payment_mood'] = channels[0].value
            setValue('payment_mood', channels[0])
        }
         if (dateRange) {
             filterParams['from_date'] = moment(start).format("YYYY-MM-DD")
             filterParams['to_date'] = moment(end).format("YYYY-MM-DD")
             delete filterParams['transaction_date']
         }
        setFilterParameters(filterParams)
        COLLECT_DEPOSITS({...filterParams, page: 1})
        COLLECT_GARAGE_LIST()
    }

    const resetFilterValues = () => {
        setValue("payment_user", "")
        setValue("garage_guid", "")
        setValue("payment_mood", "")
        setStartDate(null)
        setEndDate(null)
    }
    const resetFilter = () => {
        resetFilterValues()
        if (Object.keys(filterParameters).length > 0) {
            COLLECT_DEPOSITS({page: 1})
            COLLECT_GARAGE_LIST()
            setFilterParameters({})
        }
    }
    // ---------------------- Pagination related --------------------- //
    const onChangeOfPage = (event, newPage) => {
        setPage(newPage)
        COLLECT_DEPOSITS({...filterParameters, page: newPage})
        COLLECT_GARAGE_LIST()
    }

    // ---------------------- Export related --------------------- //
    // Export deposit list
    const exportTheList = (event, newPage) => {
        COLLECT_DEPOSITS_FOR_EXPORT({...filterParameters})
    }
    useEffect(() => {
        if (props.depositsForExport) {
            const depositsForExport = props.depositsForExport
            let presentTime = moment()
            let totalLoanText = "Total Deposits: " + depositsForExport.length
            let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                [pageTitle, "", "", "", "", "", "", "", "", ""],
                [totalLoanText, "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", ""],
                ["Recharge ID", "Date", "Borrower's Name", "Garage", "TrxID", "Wallet Number",
                    "Reference", "Channel", "Deposit Amount (Tk)", "Balance (Tk)"]
            ]
            if (Object.keys(filterParameters).length > 0) {
                let selectedChannel = getValues('payment_mood')
                if (selectedChannel) {
                    let text = "Channel: " + selectedChannel.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
                }
                let selectedGarage = getValues('garage_guid')
                if (selectedGarage) {
                    let text = "Garage: " + selectedGarage.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
                }
                let selectedBorrower = getValues('payment_user')
                if (selectedBorrower) {
                    let text = "Borrower: " + selectedBorrower.label
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
                }
                if (walletNumber) {
                    let text = "Wallet Number: " + filterParameters['sender']
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
                }
                if (dateRange) {
                    let text = "Transaction Date: " + dateRange
                    excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", ""])
                }
            }
            for (let i=0; i<depositsForExport.length; i++) {
                let garageName = depositsForExport[i].garage_name
                let borrowerName = depositsForExport[i].payment_user_name
                if (latestGarageInfo && latestGarageInfo.length > 0) {
                    let filteredData = latestGarageInfo.filter(garage => garage.pk === depositsForExport[i].garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        borrowerName = filteredData[0].garage_owner_name
                    }
                } else if (garages.length > 0) {
                    let filteredData = garages.filter(garage => garage.pk === depositsForExport[i].garage_guid)
                    if (filteredData.length > 0) {
                        garageName = filteredData[0].name
                        borrowerName = filteredData[0].garage_owner_name
                    }
                }
                excelRows.push([
                    depositsForExport[i].topup_id? depositsForExport[i].topup_id:'-',
                    moment(depositsForExport[i].created_at).format("MMM DD, YYYY - hh:mma"),
                    borrowerName? borrowerName:'-',
                    garageName,
                    depositsForExport[i].payment_mood === 'cash'? depositsForExport[i].receipt_file_name:depositsForExport[i].bkash_transaction_id,
                    depositsForExport[i].payment_mood === 'cash'? '-':depositsForExport[i].sender,
                    depositsForExport[i].payment_mood === 'cash'? depositsForExport[i].receipt_reference:depositsForExport[i].reference,
                    depositsForExport[i].payment_mood === 'cash'? 'Cash Recharge':'Digital Recharge (bKash)',
                    new Intl.NumberFormat('en-IN').format(depositsForExport[i].pay_amount.toFixed(2)),
                    new Intl.NumberFormat('en-IN').format(depositsForExport[i].total_recharged.toFixed(2))
                ])
            }
            let noDataAvailableText =  "No deposits available"
            depositsForExport.length > 0? excelRows.push(["", "", "", "", "", "", "", "", "", ""]):
                excelRows.push(["", "", "", "", noDataAvailableText, "", "", "", "", ""])
            let fileName = 'deposits_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.depositsForExport])
    useEffect(() => {
        if (props.errorMessageRegardingDepositExport) {
            showNotifications('error', props.errorMessageRegardingDepositExport)
        }
    }, [props.errorMessageRegardingDepositExport])
    // Export document
    const downloadDocument =  (data) => {
        setShowInProgressModal(true)
        COLLECT_DEPOSITS_DETAILS(data.pk)
    }
    useEffect(() => {
        if (props.depositDetails) {
            // Adding an anchor tag at DOM and click it to download the document and than removing the anchor tag
            const link = document.createElement('a')
            link.href = props.depositDetails.payment_document
            link.setAttribute('download', true)
            document.body.appendChild(link)
            setShowInProgressModal(false)
            link.click()
            link.parentNode.removeChild(link)
        }
    }, [props.depositDetails])
    useEffect(() => {
        if (props.errorMessageForDepositDetailsCollection) {
            showNotifications('error', props.errorMessageForDepositDetailsCollection)
            setShowInProgressModal(false)
        }
    }, [props.errorMessageForDepositDetailsCollection])
    const renderDownloadInProgressModal = () => {
        return <>
            <GenericModal
                size={'sm'}
                centered={true}
                showModalHeader={false}
                footer={false}
                hideCrossButton={true}
                modal={showInProgressModal}>
                <span style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <SolTypography.Text primary><h4>Downloading document ...</h4></SolTypography.Text>
                </span>
            </GenericModal>
        </>
    }

    // ---------------------- New Cash Deposit related --------------------- //
    const initiateNewCashDepositProcess = () => {
        setShowCreateCashDepositModal(true)
    }

    const hideNewCashDepositModal = () => {
        setShowCreateCashDepositModal(false)
    }

    const updateTitleOfTheNewCashDepositModal = (title) => {
        setTitleOfTheNewCashDepositModal(title)
    }

    const handleNewCashDepositProcess = (data) => {
        SAVE_NEW_CASH_DEPOSIT(data)
    }

    const renderNewCashDepositModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideNewCashDepositModal}
                modal={showCreateCashDepositModal}
                title={<h1><strong>{titleOfTheNewCashDepositModal}</strong></h1>}>
                <NewCashDeposit cancelProcess={hideNewCashDepositModal} updateTitle={updateTitleOfTheNewCashDepositModal}
                                handleNewCashDepositProcess={handleNewCashDepositProcess}/>
            </GenericModal>
        </>
    }

    useEffect(() => {
        if (props.cashDeposited && props.cashDeposited === requestCycle.success) {
            toast.success("New deposit has been made successfully!")
            hideNewCashDepositModal()
            COLLECT_DEPOSITS({...filterParameters, page: 1})
        }
    }, [props.cashDeposited])

    useEffect(() => {
        if (props.errorMessageForCashDeposit) {
            showNotifications('error', props.errorMessageForCashDeposit)
        }
    }, [props.errorMessageForCashDeposit])

    return (
        <div>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle}>
                                        <FilterListIcon color="disabled" fontSize="large" />
                                    </IconButton>
                                    <IconButton
                                        onClick={exportTheList}
                                        disabled={props.collectingDeposits || props.collectingDepositsForExport}>
                                        {props.collectingDepositsForExport? <Spinner animation={"grow"} variant={'warning'} size={"lg"}/>:
                                            <img src={require('../../../../utils/asset/download-Button.svg')} alt="Download" />}
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form onSubmit={handleSubmit(filterTheList)}>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <Form.Label>Transaction Date</Form.Label>
                                                <SolInput
                                                    name={'transaction_date'}
                                                    readOnly={true}
                                                    value={dateRange}
                                                    placeholder={"Select date range"}
                                                    ref={register()}
                                                    onClick={(e) => {
                                                        setTargetForTransactionDateField(e.target)
                                                        setShowTransactionDatepicker(!showTransactionDatepicker)
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <Form.Label>Wallet Number</Form.Label>
                                                <SolInput
                                                    name={"sender"}
                                                    type={"text"}
                                                    placeholder={"Type Wallet Number"}
                                                    autoComplete={"off"}
                                                    ref={register({
                                                        validate: {
                                                            checkLength: value => (value.toString().length === 11 || !value) || "Wallet number must be 11 digits!",
                                                            checkForValidPhoneNumber: value => (isValidPhoneNumber(value, 'BD') || !value) || "Invalid Phone number!"
                                                        }
                                                    })}
                                                />
                                                {errors.wallet_number && <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.wallet_number.message}</div>}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <Form.Label>Borrower</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name={"payment_user"}
                                                    defaultValue={""}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={'Select Borrower'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.collectingGarages}
                                                            isLoading={props.collectingGarages}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={borrowers}
                                                            isSearchable={true}
                                                            noOptionsMessage={() => "No borrowers available"}
                                                            styles={customStylesForSingleSelectField}
                                                            onChange={(selected, {action}) => {
                                                                onChange(selected)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <Form.Label>Garage</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name={"garage_guid"}
                                                    defaultValue={""}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={'Select Garage'}
                                                            classNamePrefix="react-select-sol-style"
                                                            isDisabled={props.collectingGarages}
                                                            isLoading={props.collectingGarages}
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={garages}
                                                            isSearchable={true}
                                                            noOptionsMessage={() => "No garages available"}
                                                            styles={customStylesForSingleSelectField}
                                                            onChange={(selected, {action}) => {
                                                                onChange(selected)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <Form.Group>
                                                <Form.Label>Channel</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name={"payment_mood"}
                                                    defaultValue={""}
                                                    render={( { onChange, onBlur, value, name, ref },
                                                              { invalid, isTouched, isDirty }) => (
                                                        <Select
                                                            placeholder={'Select Channel'}
                                                            classNamePrefix="react-select-sol-style"
                                                            maxMenuHeight={200}
                                                            value={value}
                                                            isClearable={true}
                                                            control={control}
                                                            inputRef={ref}
                                                            options={channels}
                                                            isSearchable={true}
                                                            styles={customStylesForSingleSelectField}
                                                            onChange={(selected, {action}) => {
                                                                onChange(selected)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="warning" size={'sm'} type="submit"
                                                    disabled={props.collectingDeposits}>
                                                {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                            </Button>
                                            <Button variant="outline-dark" size={'sm'}
                                                    disabled={props.collectingDeposits}
                                                    onClick={()=> {resetFilter()}} className={'ml-3'}>
                                                {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.collectingDeposits || deposits? <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No deposits available'}
                                columns={depositListColumns}
                                data={deposits}
                                showToolbar={false}
                                asyncPagination={true}
                                count={props.deposits?.count}
                                itemsPerPage={props.deposits?.page_size}
                                onChangePage={onChangeOfPage}
                                page={page}
                            />: <LoadingSpinner loadingSubText={'Collecting deposits ..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

                {renderDownloadInProgressModal()}
                {transactionDateSelectionPopover()}
                {renderNewCashDepositModal()}
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

Deposits.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        garages: state.commonReducer.garages,
        collectingGarages: state.commonReducer.collectingGarages,
        errorMessageForGarageListCollection: state.commonReducer.errorMessageForGarageListCollection,
        deposits: state.depositsReducer.deposits,
        errorMessageRegardingDepositCollection: state.depositsReducer.errorMessageRegardingDepositCollection,
        collectingDeposits: state.depositsReducer.collectingDeposits,
        depositsForExport: state.depositsReducer.depositsForExport,
        errorMessageRegardingDepositExport: state.depositsReducer.errorMessageRegardingDepositExport,
        collectingDepositsForExport: state.depositsReducer.collectingDepositsForExport,
        depositDetails: state.depositsReducer.depositDetails,
        errorMessageForDepositDetailsCollection: state.depositsReducer.errorMessageForDepositDetailsCollection,
        collectingDepositDetails: state.depositsReducer.collectingDepositDetails,
        garagesToShowUpdatedInfo: state.depositsReducer.garagesToShowUpdatedInfo,
        cashDeposited: state.depositsReducer.cashDeposited,
        errorMessageForCashDeposit: state.depositsReducer.errorMessageForCashDeposit
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Deposits));
